import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from './Components/Nav';
import Home from './Components/Home'
import About from './Components/About';
import Work from './Components/Work';
import Contact from './Components/Contact';
import Noisy from './noise/Noisy';

import './App.css';


import Cursor from './Components/Cursor';

function App() {
  return (
    <>
      <Router>
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/work" component={Work} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </Router>
      <Cursor />
      <div className="noise" >
        <Noisy />
      </div>

    </>
  );
}

export default App;
