import React from 'react';

import etikett from './img/etikettcut.gif';
import MLV from './img/MLV.gif';
import book from './img/books1.gif';
import tgmures from './img/tgmura1.gif';


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { Link } from 'react-router-dom';

export default function Work() {

    return (
        <>
            <Carousel className="carousel-slide">
                <div>
                    <a href="https://marielouisevogt.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-projects"
                        title="click if you're curious"
                    >
                        <img src={MLV} alt="The main page of Marie-Louise Vogt's portfolio website" />
                    </a>
                </div>
                <div>

                    <a href="http://www.etikett-radio.com/#/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-projects"
                        title="click if you're curious"
                    >
                        <img src={etikett} alt="The main page of etikett-radio website">
                        </img>

                    </a>

                </div>

                <div>
                    <a href="https://bookself.netlify.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-projects"
                        title="click if you're curious"
                    >
                        <img src={book} alt="The main page of book app" />
                    </a>
                </div>
                <div>
                    <a href="https://tg-mures.netlify.app/#home"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link-projects"
                        title="click if you're curious"
                    >
                        <img src={tgmures} alt="The main page of the website of my hometown, tg mures." />
                    </a>
                </div>
            </Carousel>
            <Link to="/" title="bring me home"><span className="back"></span></Link>
        </>
    )

}
