import React from 'react';

import { Link } from 'react-router-dom';


export default function About() {
    return (
        <>
            <div className="about">
                <span className="creative">
                    <span className="creative-white">c</span>
                    <span className="creative-red">r</span>
                    <span className="creative-white">e</span>
                    <span className="creative-red">a</span>
                    <span className="creative-white">t</span>
                    <span className="creative-red">i</span>
                    <span className="creative-white">v</span>
                    <span className="creative-red">e</span>
                </span>
                <br />
                <h2>web-development / <br />web-design</h2>
                <p>
                    freshly graduated from a one-year long<br /> full-stack web-development training.<br />
                design and detail-oriented junior web-developer, very much interested in tech for good, tech for social purposes, environmental and economical challenges, and with the aim of creating clean, accessibility-friendly solutions.
            </p>
            </div>
            <Link to="/" title="bring me home"><span className="back"></span></Link>
        </>
    )
}
