import React from 'react';



export default function Home() {
    return (
        <>
            <span className="welcome">ADRIENN TOLLAS</span>
            <h1 className="first-heading">web development /
            <br />web-design</h1>
        </>
    )
}
