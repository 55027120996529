import React from 'react';

import { Link } from 'react-router-dom';


export default function Contact() {
    return (
        <>
            <div className="hello">
                <h4>let's</h4> <span className="creative-hello">connect</span>
                <a href="mailto:adrienntollas@gmail.com" className="emailme">adrienntollas@gmail.com</a>
            </div >
            <Link to="/" title="bring me home"><span className="back"></span></Link>
        </>
    )
}
