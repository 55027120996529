import React from 'react';
import { Link } from 'react-router-dom';

export default function Nav() {
    return (
        <div>
            <nav className="navbar">
                <Link to="/about" className="top-left-about li">about</Link>
                <Link to="/work" className="top-right-work li">work</Link>
                <Link to="/contact" className="bottom-left-contact li">contact</Link>
            </nav>
        </div>
    )
}
