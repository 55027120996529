import React, { Suspense, useRef } from 'react';
import { Color } from 'three';
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Effects from './Effects/Effects';


extend({ OrbitControls })



const Controls = () => {
    const orbitRef = useRef()
    const { camera, gl } = useThree()

    useFrame(() => {
        orbitRef.current.update()
    })

    return (
        <orbitControls
            autoRotate
            maxPolarAngle={Math.PI / 3}
            minPolarAngle={Math.PI / 3}
            args={[camera, gl.domElement]}
            ref={orbitRef}
        />
    )
}

export default function Noisy() {

    return (
        <>

            <Canvas
                camera={{ fov: 80, far: 200, position: [0, 0, 80] }}
                onCreated={({ gl }) => {
                    gl.setClearColor(new Color('#222222'));
                }}
            >

                <mesh position={[1, 1, 1]}>
                    <icosahedronGeometry attach="geometry" args={[25, 0]} />
                    <meshBasicMaterial
                        attach="material"
                        color="#555555"
                        wireframe
                    />
                </mesh>
                <Controls />
                {
                    <Suspense fallback={null}>
                        <Effects />
                    </Suspense>
                }

            </Canvas>
        </>

    );

}